import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentLinks, ContentLinksContainer } from "../components/contentLinks"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Whoops!</h1>
    <p>
      The page you're looking for doesn&#39;t exist... check out the latest
      content below.
    </p>
    <ContentLinksContainer>
      <ContentLinks pageType="tutorial" title="Tutorials" />
      <ContentLinks pageType="info" title="Info" />
      <ContentLinks pageType="review" title="Reviews" />
    </ContentLinksContainer>
  </Layout>
)

export default NotFoundPage
